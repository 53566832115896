import { createAction, props } from "@ngrx/store";
import {
  AdminControllerCreateProgramRequestParams,
  AdminControllerGetProgramCampaignsRequestParams,
  AdminControllerGetProgramLocationsRequestParams,
  AnalyticsControllerGetAnalyticsRequestParams,
  CampaignDto,
  EmailConfig,
  Link,
  Program,
  ProgramAnalytics,
  WalletConfig,
  WebConfig,
  Location,
  AdminControllerAddLocationRequestParams,
  AdminControllerUpdateLocationRequestParams,
  AdminControllerDeleteLocationRequestParams,
  AdminControllerUpdateCampaignRequestParams,
  AdminControllerDeleteCampaignRequestParams,
  AdminControllerCreateCampaignRequestParams,
  AdminControllerGetProgramApiKeysRequestParams,
  ApiKey,
  AdminControllerDeleteApiKeyRequestParams,
  AdminControllerGenerateApiKeyRequestParams,
  ProgramControllerGetProgramIntegrationsRequestParams,
  ProgramIntegration,
  AdminControllerAddIntegrationRequestParams,
  AdminControllerGetProgramCustomersRequestParams,
  PaginatedCustomers,
  AdminControllerGetCustomerByIdRequestParams,
  CustomerData,
  ProgramControllerSendPushRequestParams,
  ShopifyControllerTokenExchangeRequestParams,
  AnalyticsControllerGetAnalyticsAggregateRequestParams,
  ProgramAnalyticsAggregate,
  AdminControllerUpdateProgramIntegrationRequestParams,
  AdminControllerUpdateProgramRequestParams,
  KlaviyoControllerAuthorizeRequestParams,
  KlaviyoControllerCallbackRequestParams,
  Feature,
  Integration,
  SegmentDto,
  AdminControllerGetSegmentsRequestParams,
  AdminControllerAddPointsToCustomerRequestParams,
  AdminControllerRemovePointsToCustomerRequestParams,
  AdminControllerGetCustomerEventsRequestParams,
  PaginatedCustomerEvents,
  AdminControllerGetProgramSubscriptionInfoRequestParams,
  SubscriptionInfo,
  AdminControllerCreateProgramSubscriptionRequestParams,
  RedirectLink,
  AdminControllerGetCampaignAnalyticsRequestParams,
  CampaignAnalytics,
  AdminControllerGetCampaignByIdRequestParams,
  SlugDto,
  AdminControllerCheckCampaignSlugRequestParams,
} from "../../services/api";
import { HttpErrorResponse } from "@angular/common/http";

export const getProgram = createAction(
  "@program/get-program",
  props<
  { programId: string; slug?: string; loadingFullScreen?: boolean } | { programId?: string; slug: string; loadingFullScreen?: boolean }
  >(),
);
export const getProgramSuccess = createAction("@program/get-program-success", props<{ program: Program; loadingFullScreen: boolean }>());
export const getProgramFailure = createAction(
  "@program/get-program-failure",
  props<{ error: HttpErrorResponse; loadingFullScreen: boolean }>(),
);

export const updateProgram = createAction(
  "@program/update-program",
  props<{ params: AdminControllerUpdateProgramRequestParams; toastId?: string }>(),
);
export const updateProgramSuccess = createAction("@program/update-program-success", props<{ program: Program; toastId?: string }>());
export const updateProgramFailure = createAction("@program/update-program-failure");

export const getProgramLinks = createAction("@program/get-program-links", props<{ programId: string }>());
export const getProgramLinksSuccess = createAction("@program/get-program-links-success", props<{ programLinks: Link[] }>());
export const getProgramLinksFailure = createAction("@program/get-program-links-failure", props<{ reason: string }>());

export const updateWallet = createAction("@program/update-wallet", props<{ wallet: WalletConfig }>());
export const addTemporalProgramLink = createAction("@program/add-temporal-program-link");
export const updateTemporalProgramLink = createAction(
  "@program/update-temporal-program-link",
  props<{ value: string; property: keyof Link; index: number }>(),
);
export const deleteTemporalProgramLink = createAction("@program/delete-temporal-program-link", props<{ index: number }>());

export const getCampaignLinks = createAction("@program/get-campaign-links", props<{ programId: string }>());
export const getCampaignLinksSuccess = createAction("@program/get-campaign-links-success", props<{ campaignLinks: Link[] }>());
export const getCampaignLinksFailure = createAction("@program/get-campaign-links-failure");
export const updateCampaignLinkSuccess = createAction("@program/update-campaign-link-success", props<{ campaignLink: Link }>());

export const updateEmail = createAction("@program/update-email", props<{ email: EmailConfig }>());

export const updateWebapp = createAction("@program/update-webapp", props<{ web: WebConfig }>());

export const persistProgram = createAction("@program/persist-program");
export const persistProgramSuccess = createAction("@program/persist-program-success", props<{ program: Program }>());
export const persistProgramFailure = createAction("@program/persist-program-failure", props<{ reason: string }>());

export const persistProgramLinksSuccess = createAction("@program/persist-program-links-success", props<{ programLinks: Link[] }>());

export const getIntegrations = createAction("@program/get-integrations");
export const getIntegrationsSuccess = createAction("@program/get-integrations-success", props<{ integrations: Integration[] }>());
export const getIntegrationsFailure = createAction("@program/get-integrations-failure");

export const createProgram = createAction(
  "@program/create-program",
  props<{ params: AdminControllerCreateProgramRequestParams; callback?: () => void }>(),
);
export const createProgramSuccess = createAction("@program/create-program-success", props<{ program: Program; callback?: () => void }>());
export const createProgramFailure = createAction("@program/create-program-failure", props<{ reason: string }>());

export const getAnalytics = createAction(
  "@program/get-analytics",
  props<{ params: AnalyticsControllerGetAnalyticsRequestParams; loading?: boolean }>(),
);
export const getAnalyticsSuccess = createAction("@program/get-analytics-success", props<{ analytics: ProgramAnalytics }>());
export const getAnalyticsFailure = createAction("@program/get-analytics-failure");

export const getAnalyticsAggregate = createAction(
  "@program/get-analytics-aggregate",
  props<{ params: AnalyticsControllerGetAnalyticsAggregateRequestParams; loading?: boolean }>(),
);
export const getAnalyticsAggregateSuccess = createAction(
  "@program/get-analytics-aggregate-success",
  props<{ analyticsAggregateType: string; analyticsAggregate: ProgramAnalyticsAggregate }>(),
);
export const getAnalyticsAggregateFailure = createAction("@program/get-analytics-aggregate-failure");

export const getProgramCustomers = createAction("@program/get-program-customers", props<AdminControllerGetProgramCustomersRequestParams>());
export const getProgramCustomersSuccess = createAction(
  "@program/get-program-customers-success",
  props<{ limit: number; customers: PaginatedCustomers }>(),
);
export const getProgramCustomersFailure = createAction("@program/get-program-customers-failure");

export const getCustomerById = createAction("@program/get-customer-by-id", props<AdminControllerGetCustomerByIdRequestParams>());
export const getCustomerByIdSuccess = createAction("@program/get-customer-by-id-success", props<{ customer: CustomerData }>());
export const getCustomerByIdFailure = createAction("@program/get-customer-by-id-failure");

export const getProgramCampaigns = createAction(
  "@program/get-program-campaigns",
  props<{ params: AdminControllerGetProgramCampaignsRequestParams }>(),
);
export const getProgramCampaignsSuccess = createAction("@program/get-program-campaigns-success", props<{ campaigns: CampaignDto[] }>());
export const getProgramCampaignsFailure = createAction("@program/get-program-campaigns-failure");

export const getProgramLocations = createAction("@program/get-program-locations", props<AdminControllerGetProgramLocationsRequestParams>());
export const getProgramLocationsSuccess = createAction("@program/get-program-locations-success", props<{ locations: Location[] }>());
export const getProgramLocationsFailure = createAction("@program/get-program-locations-failure");

export const addLocation = createAction("@program/add-location", props<AdminControllerAddLocationRequestParams>());
export const addLocationSuccess = createAction("@program/add-location-success", props<{ location: Location }>());
export const addLocationFailure = createAction("@program/add-location-failure", props<{ formError: string }>());

export const updateLocation = createAction("@program/update-location", props<AdminControllerUpdateLocationRequestParams>());
export const updateLocationSuccess = createAction("@program/update-location-success", props<{ location: Location }>());
export const updateLocationFailure = createAction("@program/update-location-failure", props<{ formError: string }>());

export const deleteLocation = createAction(
  "@program/delete-location",
  props<{ params: AdminControllerDeleteLocationRequestParams; callback?: () => void }>(),
);
export const deleteLocationSuccess = createAction(
  "@program/delete-location-success",
  props<{ locationId: string; callback?: () => void }>(),
);
export const deleteLocationFailure = createAction("@program/delete-location-failure", props<{ formError: string }>());

export const createCampaign = createAction(
  "@program/create-campaign",
  props<{ params: AdminControllerCreateCampaignRequestParams; links?: Link[] }>(),
);
export const createCampaignSuccess = createAction("@program/create-campaign-success", props<{ campaign: CampaignDto }>());
export const createCampaignFailure = createAction("@program/create-campaign-failure", props<{ formError: string }>());

export const updateCampaign = createAction(
  "@program/update-campaign",
  props<{ params: AdminControllerUpdateCampaignRequestParams; links?: Link[] }>(),
);
export const updateCampaignSuccess = createAction("@program/update-campaign-success", props<{ campaign: CampaignDto }>());
export const updateCampaignFailure = createAction("@program/update-campaign-failure", props<{ formError: string }>());

export const deleteCampaign = createAction(
  "@program/delete-campaign",
  props<{ params: AdminControllerDeleteCampaignRequestParams; callback?: () => void }>(),
);
export const deleteCampaignSuccess = createAction(
  "@program/delete-campaign-success",
  props<{ campaignId: string; callback?: () => void }>(),
);
export const deleteCampaignFailure = createAction("@program/delete-campaign-failure", props<{ formError: string }>());

export const resetFormError = createAction("@program/reset-form-error");

export const getProgramApiKeys = createAction("@program/get-program-api-keys", props<AdminControllerGetProgramApiKeysRequestParams>());
export const getProgramApiKeysSuccess = createAction("@program/get-program-api-keys-success", props<{ apiKeys: ApiKey[] }>());
export const getProgramApiKeysFailure = createAction("@program/get-program-api-keys-failure");

export const generateApiKey = createAction("@program/generate-api-key", props<AdminControllerGenerateApiKeyRequestParams>());
export const generateApiKeySuccess = createAction("@program/generate-api-key-success", props<{ apiKey: ApiKey }>());
export const generateApiKeyFailure = createAction("@program/generate-api-key-failure");

export const deleteApiKey = createAction("@program/delete-api-key", props<AdminControllerDeleteApiKeyRequestParams>());
export const deleteApiKeySuccess = createAction("@program/delete-api-key-success", props<{ apiKeyId: string }>());
export const deleteApiKeyFailure = createAction("@program/delete-api-key-failure");

export const getProgramIntegrations = createAction(
  "@program/get-program-integrations",
  props<ProgramControllerGetProgramIntegrationsRequestParams>(),
);
export const getProgramIntegrationsSuccess = createAction(
  "@program/get-program-integrations-success",
  props<{ programIntegrations: ProgramIntegration[] }>(),
);
export const getProgramIntegrationsFailure = createAction("@program/get-program-integrations-failure");

export const addIntegration = createAction("@program/add-integration", props<AdminControllerAddIntegrationRequestParams>());
export const addIntegrationSuccess = createAction("@program/add-integration-success");
export const addIntegrationFailure = createAction("@program/add-integration-failure");

export const updateProgramIntegration = createAction(
  "@program/update-program-integration",
  props<AdminControllerUpdateProgramIntegrationRequestParams & { integrationName: string; callback: () => void }>(),
);
export const updateProgramIntegrationSuccess = createAction(
  "@program/update-program-integration-success",
  props<{ programIntegration: ProgramIntegration; integrationName: string; callback: () => void }>(),
);
export const updateProgramIntegrationFailure = createAction("@program/update-program-integration-failure", props<{ formError: string }>());

export const addProgramIntegration = createAction(
  "@program/add-program-integration",
  props<AdminControllerAddIntegrationRequestParams & { integrationName: string; callback: () => void }>(),
);
export const addProgramIntegrationSuccess = createAction(
  "@program/add-program-integration-success",
  props<{ programIntegration: ProgramIntegration; integrationName: string; callback: () => void }>(),
);
export const addProgramIntegrationFailure = createAction("@program/add-program-integration-failure", props<{ formError: string }>());

export const tokenExchange = createAction("@program/token-exchange", props<ShopifyControllerTokenExchangeRequestParams>());
export const tokenExchangeSuccess = createAction("@program/token-exchange-success");
export const tokenExchangeFailure = createAction("@program/token-exchange-failure");

export const sendPush = createAction("@program/send-push", props<ProgramControllerSendPushRequestParams & { callback: () => void }>());
export const sendPushSuccess = createAction("@program/send-push-success", props<{ callback: () => void }>());
export const sendPushFailure = createAction("@program/send-push-failure", props<{ formError: string }>());

export const klaviyoAuthorize = createAction("@program/klaviyo-authorize", props<KlaviyoControllerAuthorizeRequestParams>());
export const klaviyoAuthorizeSuccess = createAction("@program/klaviyo-authorize-success", props<{ redirectUri: string }>());
export const klaviyoAuthorizeFailure = createAction("@program/klaviyo-authorize-failure");

export const klaviyoCallback = createAction("@program/klaviyo-callback", props<KlaviyoControllerCallbackRequestParams>());
export const klaviyoCallbackSuccess = createAction("@program/klaviyo-callback-success", props<{ programId: string }>());
export const klaviyoCallbackFailure = createAction("@program/klaviyo-callback-failure");

export const setCustomersSearchTerm = createAction("@program/set-customers-search-term", props<{ customersSearchTerm: string }>());

export const resetCustomersPagination = createAction("@program/reset-customers-pagination");

export const setIntegrationsBySearchTerm = createAction(
  "@program/set-integrations-by-search-term",
  props<{ integrationsSearchTerm: string }>(),
);

export const getFeatures = createAction("@program/get-features");
export const getFeaturesSuccess = createAction("@program/get-features-success", props<{ features: Feature[] }>());
export const getFeaturesFailure = createAction("@program/get-features-failure");

export const toggleIntegrationFilter = createAction("@program/toggle-integration-filter", props<{ integrationFilter: string }>());

export const getSegments = createAction("@program/get-segments", props<AdminControllerGetSegmentsRequestParams>());
export const getSegmentsSuccess = createAction("@program/get-segments-success", props<{ segments: SegmentDto[] }>());
export const getSegmentsFailure = createAction("@program/get-segments-failure");

export const addPointsToCustomer = createAction(
  "@program/add-points-to-customer",
  props<AdminControllerAddPointsToCustomerRequestParams & { callback: () => void }>(),
);
export const addPointsToCustomerSuccess = createAction(
  "@program/add-points-to-customer-success",
  props<{ customer: CustomerData; callback: () => void }>(),
);
export const addPointsToCustomerFailure = createAction("@program/add-points-to-customer-failure", props<{ formError: string }>());

export const removePointsToCustomer = createAction(
  "@program/remove-points-to-customer",
  props<AdminControllerRemovePointsToCustomerRequestParams & { callback: () => void }>(),
);
export const removePointsToCustomerSuccess = createAction(
  "@program/remove-points-to-customer-success",
  props<{ customer: CustomerData; callback: () => void }>(),
);
export const removePointsToCustomerFailure = createAction("@program/remove-points-to-customer-failure", props<{ formError: string }>());

export const getCustomerEvents = createAction("@program/get-customer-events", props<AdminControllerGetCustomerEventsRequestParams>());
export const getCustomerEventsSuccess = createAction(
  "@program/get-customer-events-success",
  props<{ customerId: string; paginatedCustomerEvents: PaginatedCustomerEvents }>(),
);
export const getCustomerEventsFailure = createAction("@program/get-customer-events-failure");

export const getProgramSubscriptionInfo = createAction(
  "@program/get-program-subscription-info",
  props<{ params: AdminControllerGetProgramSubscriptionInfoRequestParams; redirect?: boolean }>(),
);
export const getProgramSubscriptionInfoSuccess = createAction(
  "@program/get-program-subscription-info-success",
  props<{ subscriptionInfo: SubscriptionInfo; redirect?: boolean }>(),
);
export const getProgramSubscriptionInfoFailure = createAction("@program/get-program-subscription-info-failure");

export const createProgramSubscription = createAction(
  "@program/create-program-subscription",
  props<{ params: AdminControllerCreateProgramSubscriptionRequestParams; navigate?: boolean }>(),
);
export const createProgramSubscriptionSuccess = createAction(
  "@program/create-program-subscription-success",
  props<{ redirectLink: RedirectLink; navigate?: boolean }>(),
);
export const createProgramSubscriptionFailure = createAction("@program/create-program-subscription-failure");

export const setLoadingCreateProgramSubscription = createAction(
  "@program/set-loading-create-program-subscription",
  props<{ loadingCreateSubscription: boolean }>(),
);

export const setLoadingKlaviyoAuth = createAction("@program/set-loading-klaviyo-auth", props<{ loadingKlaviyoAuth: boolean }>());

export const getCampaignAnalytics = createAction(
  "@program/get-campaign-analytics",
  props<AdminControllerGetCampaignAnalyticsRequestParams>(),
);
export const getCampaignAnalyticsSuccess = createAction(
  "@program/get-campaign-analytics-success",
  props<{ campaignId: string; campaignAnalytic: CampaignAnalytics }>(),
);
export const getCampaignAnalyticsFailure = createAction("@program/get-campaign-analytics-failure");

export const getCampaignById = createAction("@program/get-campaign-by-id", props<AdminControllerGetCampaignByIdRequestParams>());
export const getCampaignByIdSuccess = createAction("@program/get-campaign-by-id-success", props<{ campaign: CampaignDto }>());
export const getCampaignByIdFailure = createAction("@program/get-campaign-by-id-failure");

export const checkCampaignSlug = createAction("@program/check-campaign-slug", props<AdminControllerCheckCampaignSlugRequestParams>());
export const checkCampaignSlugSuccess = createAction("@program/check-campaign-slug-success", props<{ response: SlugDto }>());
export const checkCampaignSlugFailure = createAction("@program/check-campaign-slug-failure");

export const resetCampaignSlug = createAction("@program/reset-campaign-slug");
