import { Store } from "@ngrx/store";
import { RootState, appActions } from "./store";
import { AppRoutes } from "./enums";
import { SHOPIFY_HOST } from "./constants";
import { WalletParams } from "./interfaces";
import { environment } from "../environments/environment";
import { ShopifyAppBridgeService } from "./services/shopify-app-bridge/shopify-app-bridge.service";
import { Router } from "@angular/router";
import { Program } from "./services/api";

export const initializeApp =
  (store: Store<RootState>, shopifyAppBridgeService: ShopifyAppBridgeService, router: Router): Function =>
    async () => {
    // Convercus
      try {
        const { accessToken, programId, programName, integrationName, activeSideBar }: WalletParams = JSON.parse(window.name);

        store.dispatch(appActions.setAccessToken({ accessToken }));
        store.dispatch(appActions.setSideNav({ active: activeSideBar, hasLogOut: false }));
        store.dispatch(appActions.setIframeProgramId({ iframeProgramId: programId }));
        store.dispatch(appActions.setIframeProgramName({ iframeProgramName: programName }));
        store.dispatch(appActions.setIframeIntegrationName({ iframeIntegrationName: integrationName as Program.IntegrationEnum }));
      } catch (error) {}

      // Shopify
      try {
        const redirectUrl = `https://${environment.host}`;

        const urlParams = new URLSearchParams(window.location.search);

        const shop = urlParams.get("shop");
        const idToken = urlParams.get("id_token");

        if (shop) {
          if (idToken) {
            const host = urlParams.get("host");
            const programSlug = shop.replace(SHOPIFY_HOST, "");

            await shopifyAppBridgeService.initializeAppBridge({ apiKey: environment.shopifyClientId, host }, programSlug, shop);

            router.navigate([programSlug, AppRoutes.design]);
          } else {
            window.location.href = `https://${shop}/admin/oauth/authorize?client_id=${environment.shopifyClientId}&scope=&redirect_uri=${redirectUrl}`;
          }
        }
      } catch (error) {}

    // Shared
    // try {
    //   const userLang = navigator.language.split("-")[0] as Languages;
    //   store.dispatch(appActions.changeLanguage({ language: AVAILABLE_LANGUAGES.includes(userLang) ? userLang : DEFAULT_LANGUAGE }));
    // } catch (e) {
    //   console.error("Setting Language Problem: ", e);
    // }
    };
