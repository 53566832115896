/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1121
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgramConfig } from './program-config';


export interface Program { 
    id: string;
    name: string;
    slug: string;
    integration: Program.IntegrationEnum;
    contactEmail: string;
    freePasses: number;
    consentOptInUrl: string;
    balanceNotifications: boolean;
    balanceNotificationsMessage: string;
    tierNotifications: boolean;
    tierNotificationsMessage: string;
    locationNotifications: boolean;
    marketingNotifications: boolean;
    configurations: ProgramConfig;
    downloadEmail: boolean;
    verifyEmail: boolean;
    createdAt: string;
    updatedAt: string;
}
export namespace Program {
    export type IntegrationEnum = 'api' | 'convercus' | 'jericommerce' | 'klaviyo' | 'shopify/loyalty-lion' | 'shopify';
    export const IntegrationEnum = {
        Api: 'api' as IntegrationEnum,
        Convercus: 'convercus' as IntegrationEnum,
        Jericommerce: 'jericommerce' as IntegrationEnum,
        Klaviyo: 'klaviyo' as IntegrationEnum,
        ShopifyLoyaltyLion: 'shopify/loyalty-lion' as IntegrationEnum,
        Shopify: 'shopify' as IntegrationEnum
    };
}


