import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from "@ngrx/store";
import { PROGRAM_FEATURE_KEY, ProgramIntegrations, ProgramState } from "./state";
import {
  CampaignDto,
  CampaignAnalytics,
  CustomerData,
  CustomerEventDto,
  Integration,
  IntegrationFeature,
  Link,
  Location,
  Program,
  ProgramAnalyticsAggregate,
  ProgramIntegration,
  SegmentDto,
  SubscriptionInfo,
} from "../../services/api";
import { RootState } from "..";
import { AnalyticsDataTable } from "../../interfaces";
import { selectIframeIntegrationName } from "../app/selectors";

const getState = createFeatureSelector<ProgramState>(PROGRAM_FEATURE_KEY);

export const selectLoading = createSelector(getState, state => state.loading);

export const selectFormError = createSelector(getState, state => state.formError);

export const selectCustomersSearchTerm = createSelector(getState, state => state.customersSearchTerm);

export const selectLoadingFullScreen = createSelector(getState, state => state.loadingFullScreen);

export const selectLoadingCreateSubscription = createSelector(getState, state => state.loadingCreateSubscription);

export const selectLoadingKlaviyoAuth = createSelector(getState, state => state.loadingKlaviyoAuth);

export const selectLoadingAnalytics = createSelector(getState, state => state.loadingAnalytics);

export const selectProgram = createSelector(getState, state => state.program);

export const selectProgramLinks = createSelector(getState, state => state.programLinks);

export const selectCampaignLinks = createSelector(getState, state => Object.values(state.campaignLinks));

export const selectApiKeys = createSelector(getState, state => Object.values(state.apiKeys));

export const selectIntegrations = createSelector(getState, state => state.integrations);

export const selectSubscriptionInfo = createSelector(getState, state => state.subscriptionInfo);

export const selectHasActiveSubscription = createSelector(
  getState,
  state =>
    state.subscriptionInfo?.status !== null &&
    state.subscriptionInfo?.status !== SubscriptionInfo.StatusEnum[1] &&
    state.subscriptionInfo?.status !== SubscriptionInfo.StatusEnum._0,
);

export const isIntegrationConnected = (integration: Integration, programIntegrations: ProgramIntegrations): boolean =>
  Object.values(programIntegrations)?.some(
    programIntegration => programIntegration.enabledFeatures?.length && programIntegration.integrationId === integration.id,
  );

export const selectIsIntegrationConnected = (integration: Integration): MemoizedSelector<RootState, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(getState, state => isIntegrationConnected(integration, state.programIntegrations));

export const selectFilteredAndSortedIntegrations = createSelector(getState, selectIframeIntegrationName, (state, iframeIntegrationName) => {
  const filterIntegration = (integration: Integration): boolean => {
    const matchesSearchTerm = state.integrationsSearchTerm
      ? integration.name?.toLowerCase().includes(state.integrationsSearchTerm.toLowerCase())
      : true;

    const matchesFeatures = integration.integrationFeatures?.some(feature => state.integrationsFilters.includes(feature.feature.name));

    return integration.active && matchesSearchTerm && matchesFeatures;
  };

  const filterByName = (integration: Integration, namesToInclude: string[], namesToExclude: string[] = []): boolean => {
    const include = namesToInclude.length === 0 || namesToInclude.includes(integration.name);
    const exclude = namesToExclude.includes(integration.name);
    return include && !exclude && filterIntegration(integration);
  };

  const filteredIntegrations = state.integrations.filter(integration => {
    switch (iframeIntegrationName) {
      case Program.IntegrationEnum.Convercus:
        return filterByName(integration, [Program.IntegrationEnum.Convercus]);
      case Program.IntegrationEnum.Shopify:
        return filterByName(integration, [], [Program.IntegrationEnum.Convercus]);
      default:
        return filterByName(integration, [], [Program.IntegrationEnum.Shopify, Program.IntegrationEnum.ShopifyLoyaltyLion]);
    }
  });

  return filteredIntegrations.sort((a, b) => {
    const isConnectedA = isIntegrationConnected(a, state.programIntegrations);
    const isConnectedB = isIntegrationConnected(b, state.programIntegrations);

    if (isConnectedA && !isConnectedB) return -1;
    else if (!isConnectedA && isConnectedB) return 1;
    else return a.name.localeCompare(b.name);
  });
});

export const selectProgramIntegrations = createSelector(getState, state => Object.values(state.programIntegrations));

export const selectIntegrationsSearchTerm = createSelector(getState, state => state.integrationsSearchTerm);

export const selectAnalytics = createSelector(getState, state => state.analytics);

export const selectAnalyticsAggregate = (
  type: string,
): MemoizedSelector<RootState, ProgramAnalyticsAggregate, DefaultProjectorFn<ProgramAnalyticsAggregate>> =>
  createSelector(getState, state => state.analyticsAggregate[type]);

export const selectAnalyticsAggregateTable = (
  type: string,
): MemoizedSelector<RootState, AnalyticsDataTable[], DefaultProjectorFn<AnalyticsDataTable[]>> =>
  createSelector(getState, state =>
    Object.entries(state.analyticsAggregate[type]?.installedByDay || {}).map(([key, value]) => ({
      day: key,
      apple: value.apple || 0,
      google: value.google || 0,
      other: value.other || 0,
      total: (value.apple || 0) + (value.google || 0) + (value.other || 0),
    })),
  );

export const selectCustomers = createSelector(getState, state => Object.values(state.customers));

export const selectCustomerDataById = (customerId: string): MemoizedSelector<RootState, CustomerData, DefaultProjectorFn<CustomerData>> =>
  createSelector(getState, state => state.customersData[customerId]);

export const selectCustomerEventsById = (
  customerId: string,
): MemoizedSelector<RootState, CustomerEventDto[], DefaultProjectorFn<CustomerEventDto[]>> =>
  createSelector(getState, state => state.customersEvents[customerId]);

export const selectCustomersPaginationData = createSelector(getState, state => state.customersPaginationData);

export const selectCustomersEventsPaginationData = createSelector(getState, state => state.customersEventsPaginationData);

export const selectCampaigns = createSelector(getState, state => Object.values(state.campaigns));

export const selectLocationBasedCampaigns = createSelector(getState, state =>
  Object.values(state.campaigns)
    .filter(campaign => campaign.type === CampaignDto.TypeEnum._0)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
);

export const selectScheduledCampaigns = createSelector(getState, state =>
  Object.values(state.campaigns)
    .filter(campaign => campaign.type === CampaignDto.TypeEnum._1)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
);

export const selectLinksCampaigns = createSelector(getState, state =>
  Object.values(state.campaigns)
    .filter(campaign => campaign.type === CampaignDto.TypeEnum._2)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
);

export const selectTriggeredBasedCampaigns = createSelector(getState, state =>
  Object.values(state.campaigns)
    .filter(campaign => campaign.type === CampaignDto.TypeEnum._3)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
);

export const selectAnalyticsCampaigns = createSelector(getState, state =>
  Object.values(state.campaigns)
    .filter(campaign => campaign.type === CampaignDto.TypeEnum._1 || campaign.type === CampaignDto.TypeEnum._3)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
);

export const selectLocations = createSelector(getState, state => Object.values(state.locations));

export const selectLocation = (id: string): MemoizedSelector<RootState, Location, DefaultProjectorFn<Location>> =>
  createSelector(getState, state => state.locations[id]);

export const selectCampaign = (id: string): MemoizedSelector<RootState, CampaignDto, DefaultProjectorFn<CampaignDto>> =>
  createSelector(getState, state => state.campaigns[id]);

export const selectCampaignAnalytics = (
  id: string,
): MemoizedSelector<RootState, CampaignAnalytics, DefaultProjectorFn<CampaignAnalytics>> =>
  createSelector(getState, state => state.campaignAnalytics[id]);

export const selectLink = (id: string): MemoizedSelector<RootState, Link, DefaultProjectorFn<Link>> =>
  createSelector(getState, state => state.campaignLinks[id]);

export const selectLocationAddressesByIds = (ids: string[]): MemoizedSelector<RootState, string[], DefaultProjectorFn<string[]>> =>
  createSelector(getState, state =>
    Object.values(state.locations).reduce((locations, location) => {
      if (ids.includes(location.id)) locations.push(location.address);
      return locations;
    }, []),
  );

export const selectLocationRelevantTextById = (id: string): MemoizedSelector<RootState, string, DefaultProjectorFn<string>> =>
  createSelector(getState, state => state.locations[id]?.relevantText);

export const selectLinksUrlsByIds = (ids: string[]): MemoizedSelector<RootState, string[], DefaultProjectorFn<string[]>> =>
  createSelector(getState, state =>
    Object.values(state.campaignLinks).reduce((links, link) => {
      if (ids.includes(link.id)) links.push(link.url);
      return links;
    }, []),
  );

export const selectIntegrationById = (id: string): MemoizedSelector<RootState, Integration, DefaultProjectorFn<Integration>> =>
  createSelector(getState, state => state.integrations.find(integration => id === integration.id));

export const selectIsFeatureEnabled = (feature: IntegrationFeature): MemoizedSelector<RootState, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(getState, state =>
    Object.values(state.programIntegrations)?.some(
      programIntegration =>
        programIntegration.enabledFeatures.includes(feature.featureId) && programIntegration.integrationId === feature.integrationId,
    ),
  );

export const selectIsFeatureEnabledByName = (featureName: string): MemoizedSelector<RootState, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(getState, state =>
    Object.values(state.programIntegrations)?.some(programIntegration => {
      const features = programIntegration?.enabledFeatures?.map(id => state.features[id]);
      return features?.some(feature => feature?.name === featureName);
    }),
  );

export const selectIsFeatureEnabledByNames = (
  IntegrationName: string,
  featureName: string,
): MemoizedSelector<RootState, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(getState, state =>
    Object.values(state.programIntegrations)?.some(programIntegration => {
      const features = programIntegration?.enabledFeatures?.map(id => state.features[id]);
      return programIntegration?.integration?.name === IntegrationName && features?.some(feature => feature?.name === featureName);
    }),
  );

export const selectFeatures = createSelector(getState, state => Object.values(state.features));

export const selectIntegrationsFilters = createSelector(getState, state => state.integrationsFilters);

export const selectIntegrationByName = (name: string): MemoizedSelector<RootState, Integration, DefaultProjectorFn<Integration>> =>
  createSelector(getState, state => state.integrations.find(integration => integration.name === name));

export const selectIntegrationByFeature = (
  featureName: string,
): MemoizedSelector<RootState, Integration[], DefaultProjectorFn<Integration[]>> =>
  createSelector(getState, selectIframeIntegrationName, (state, iframeIntegrationName) => {
    switch (iframeIntegrationName) {
      case Program.IntegrationEnum.Convercus:
        return state.integrations.filter(
          integration =>
            integration.active &&
            integration.name === Program.IntegrationEnum.Convercus &&
            integration.integrationFeatures.some(feature => feature.feature.name === featureName),
        );
      case Program.IntegrationEnum.Shopify:
        return state.integrations.filter(integration =>
          featureName === "users"
            ? integration.active &&
              integration.name !== Program.IntegrationEnum.Convercus &&
              integration.name !== Program.IntegrationEnum.Api &&
              integration.integrationFeatures.some(feature => feature.feature.name === featureName)
            : integration.active &&
              integration.name !== Program.IntegrationEnum.Convercus &&
              integration.integrationFeatures.some(feature => feature.feature.name === featureName),
        );
      default:
        return state.integrations.filter(
          integration =>
            integration.active &&
            integration.name !== Program.IntegrationEnum.Shopify &&
            integration.name !== Program.IntegrationEnum.ShopifyLoyaltyLion &&
            integration.integrationFeatures.some(feature => feature.feature.name === featureName),
        );
    }
  });

export const selectProgramIntegrationById = (
  id: string,
): MemoizedSelector<RootState, ProgramIntegration, DefaultProjectorFn<ProgramIntegration>> =>
  createSelector(getState, state => Object.values(state.programIntegrations).find(programIntegration => programIntegration.id === id));

export const selectProgramIntegrationByName = (
  name: string,
): MemoizedSelector<RootState, ProgramIntegration, DefaultProjectorFn<ProgramIntegration>> =>
  createSelector(getState, state =>
    Object.values(state.programIntegrations).find(programIntegration => programIntegration["integration"].name === name),
  );

export const selectSegments = createSelector(getState, state => state.segments);

export const selectSegmentsMultiSelect = createSelector(getState, state =>
  state.segments.reduce((segments, segment) => {
    let segmentIntegration = segments[segment.integrationId];

    if (segmentIntegration) {
      segmentIntegration.push(segment);
      segmentIntegration.sort((a: SegmentDto, b: SegmentDto) => a.name.localeCompare(b.name));
    } else {
      segmentIntegration = [segment];
    }

    return {
      ...segments,
      [segment.integrationId]: segmentIntegration,
    };
  }, {}),
);

export const selectCampaignSlug = createSelector(getState, state => state.campaignSlug);
