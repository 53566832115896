/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1121
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { KlaviyoAuthorizeResponse } from '../model/models';
import { ShopifyAction } from '../model/models';
import { ShopifyActionResult } from '../model/models';
import { ShopifyCustomer } from '../model/models';
import { ShopifyCustomerRedact } from '../model/models';
import { ShopifyCustomerRequest } from '../model/models';
import { ShopifyStoreRedact } from '../model/models';
import { TokenExchange } from '../model/models';
import { WebhookPayload } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    IntegrationServiceInterface,
    ApiControllerDeleteCustomerRequestParams,
    ApiControllerListenToChangesRequestParams,
    ConvercusControllerHandleConvercusBalanceChangedEventRequestParams,
    ConvercusControllerHandleConvercusTierStatusChangedEventRequestParams,
    KlaviyoControllerAuthorizeRequestParams,
    KlaviyoControllerCallbackRequestParams,
    LoyaltyLionControllerHandleLoyaltyLionCustomerChangedEventRequestParams,
    ShopifyControllerApproachingCappedAmountRequestParams,
    ShopifyControllerCustomerUpdatedRequestParams,
    ShopifyControllerHandleShopifyActionAddBalanceRequestParams,
    ShopifyControllerHandleShopifyActionAssignCampaignRequestParams,
    ShopifyControllerHandleShopifyActionGetDownloadLinkRequestParams,
    ShopifyControllerHandleShopifyActionRemoveBalanceRequestParams,
    ShopifyControllerHandleShopifyActionSendPushRequestParams,
    ShopifyControllerRequestToDeleteCustomerDataRequestParams,
    ShopifyControllerRequestToDeleteStoreDataRequestParams,
    ShopifyControllerRequestViewCustomerStoredDataRequestParams,
    ShopifyControllerSubscriptionUpdateRequestParams,
    ShopifyControllerTokenExchangeRequestParams
} from './integration.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class IntegrationService implements IntegrationServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiControllerDeleteCustomer(requestParameters: ApiControllerDeleteCustomerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public apiControllerDeleteCustomer(requestParameters: ApiControllerDeleteCustomerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public apiControllerDeleteCustomer(requestParameters: ApiControllerDeleteCustomerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public apiControllerDeleteCustomer(requestParameters: ApiControllerDeleteCustomerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling apiControllerDeleteCustomer.');
        }
        const email = requestParameters.email;
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling apiControllerDeleteCustomer.');
        }
        const apiKey = requestParameters.apiKey;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('api-key', String(apiKey));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/api`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiControllerListenToChanges(requestParameters: ApiControllerListenToChangesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public apiControllerListenToChanges(requestParameters: ApiControllerListenToChangesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public apiControllerListenToChanges(requestParameters: ApiControllerListenToChangesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public apiControllerListenToChanges(requestParameters: ApiControllerListenToChangesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const webhookPayload = requestParameters.webhookPayload;
        if (webhookPayload === null || webhookPayload === undefined) {
            throw new Error('Required parameter webhookPayload was null or undefined when calling apiControllerListenToChanges.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling apiControllerListenToChanges.');
        }
        const apiKey = requestParameters.apiKey;

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('api-key', String(apiKey));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/api`,
            webhookPayload,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public convercusControllerHandleConvercusBalanceChangedEvent(requestParameters: ConvercusControllerHandleConvercusBalanceChangedEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public convercusControllerHandleConvercusBalanceChangedEvent(requestParameters: ConvercusControllerHandleConvercusBalanceChangedEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public convercusControllerHandleConvercusBalanceChangedEvent(requestParameters: ConvercusControllerHandleConvercusBalanceChangedEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public convercusControllerHandleConvercusBalanceChangedEvent(requestParameters: ConvercusControllerHandleConvercusBalanceChangedEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const body = requestParameters.body;
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling convercusControllerHandleConvercusBalanceChangedEvent.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling convercusControllerHandleConvercusBalanceChangedEvent.');
        }
        const apiKey = requestParameters.apiKey;

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('api-key', String(apiKey));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/convercus/balance`,
            body,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public convercusControllerHandleConvercusTierStatusChangedEvent(requestParameters: ConvercusControllerHandleConvercusTierStatusChangedEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public convercusControllerHandleConvercusTierStatusChangedEvent(requestParameters: ConvercusControllerHandleConvercusTierStatusChangedEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public convercusControllerHandleConvercusTierStatusChangedEvent(requestParameters: ConvercusControllerHandleConvercusTierStatusChangedEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public convercusControllerHandleConvercusTierStatusChangedEvent(requestParameters: ConvercusControllerHandleConvercusTierStatusChangedEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const body = requestParameters.body;
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling convercusControllerHandleConvercusTierStatusChangedEvent.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling convercusControllerHandleConvercusTierStatusChangedEvent.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/convercus/tier-status`,
            body,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public klaviyoControllerAuthorize(requestParameters: KlaviyoControllerAuthorizeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<KlaviyoAuthorizeResponse>;
    public klaviyoControllerAuthorize(requestParameters: KlaviyoControllerAuthorizeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<KlaviyoAuthorizeResponse>>;
    public klaviyoControllerAuthorize(requestParameters: KlaviyoControllerAuthorizeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<KlaviyoAuthorizeResponse>>;
    public klaviyoControllerAuthorize(requestParameters: KlaviyoControllerAuthorizeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling klaviyoControllerAuthorize.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<KlaviyoAuthorizeResponse>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/klaviyo/oauth/authorize`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public klaviyoControllerCallback(requestParameters: KlaviyoControllerCallbackRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public klaviyoControllerCallback(requestParameters: KlaviyoControllerCallbackRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public klaviyoControllerCallback(requestParameters: KlaviyoControllerCallbackRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public klaviyoControllerCallback(requestParameters: KlaviyoControllerCallbackRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling klaviyoControllerCallback.');
        }
        const code = requestParameters.code;
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling klaviyoControllerCallback.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'code');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/klaviyo/oauth/callback`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loyaltyLionControllerHandleLoyaltyLionCustomerChangedEvent(requestParameters: LoyaltyLionControllerHandleLoyaltyLionCustomerChangedEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public loyaltyLionControllerHandleLoyaltyLionCustomerChangedEvent(requestParameters: LoyaltyLionControllerHandleLoyaltyLionCustomerChangedEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public loyaltyLionControllerHandleLoyaltyLionCustomerChangedEvent(requestParameters: LoyaltyLionControllerHandleLoyaltyLionCustomerChangedEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public loyaltyLionControllerHandleLoyaltyLionCustomerChangedEvent(requestParameters: LoyaltyLionControllerHandleLoyaltyLionCustomerChangedEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const body = requestParameters.body;
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling loyaltyLionControllerHandleLoyaltyLionCustomerChangedEvent.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling loyaltyLionControllerHandleLoyaltyLionCustomerChangedEvent.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/loyalty-lion/customers`,
            body,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listen to shopify \&#39;app_subscriptions/approaching_capped_amount\&#39; topic
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerApproachingCappedAmount(requestParameters: ShopifyControllerApproachingCappedAmountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerApproachingCappedAmount(requestParameters: ShopifyControllerApproachingCappedAmountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerApproachingCappedAmount(requestParameters: ShopifyControllerApproachingCappedAmountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerApproachingCappedAmount(requestParameters: ShopifyControllerApproachingCappedAmountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling shopifyControllerApproachingCappedAmount.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/shopify/approaching-capped-amount`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listen to shopify \&#39;customers/create\&#39; and \&#39;customers/update\&#39; topics
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerCustomerUpdated(requestParameters: ShopifyControllerCustomerUpdatedRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerCustomerUpdated(requestParameters: ShopifyControllerCustomerUpdatedRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerCustomerUpdated(requestParameters: ShopifyControllerCustomerUpdatedRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerCustomerUpdated(requestParameters: ShopifyControllerCustomerUpdatedRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyCustomer = requestParameters.shopifyCustomer;
        if (shopifyCustomer === null || shopifyCustomer === undefined) {
            throw new Error('Required parameter shopifyCustomer was null or undefined when calling shopifyControllerCustomerUpdated.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling shopifyControllerCustomerUpdated.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/shopify/customers`,
            shopifyCustomer,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerHandleShopifyActionAddBalance(requestParameters: ShopifyControllerHandleShopifyActionAddBalanceRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerHandleShopifyActionAddBalance(requestParameters: ShopifyControllerHandleShopifyActionAddBalanceRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerHandleShopifyActionAddBalance(requestParameters: ShopifyControllerHandleShopifyActionAddBalanceRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerHandleShopifyActionAddBalance(requestParameters: ShopifyControllerHandleShopifyActionAddBalanceRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyAction = requestParameters.shopifyAction;
        if (shopifyAction === null || shopifyAction === undefined) {
            throw new Error('Required parameter shopifyAction was null or undefined when calling shopifyControllerHandleShopifyActionAddBalance.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/integrations/shopify/actions/add-balance`,
            shopifyAction,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerHandleShopifyActionAssignCampaign(requestParameters: ShopifyControllerHandleShopifyActionAssignCampaignRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerHandleShopifyActionAssignCampaign(requestParameters: ShopifyControllerHandleShopifyActionAssignCampaignRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerHandleShopifyActionAssignCampaign(requestParameters: ShopifyControllerHandleShopifyActionAssignCampaignRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerHandleShopifyActionAssignCampaign(requestParameters: ShopifyControllerHandleShopifyActionAssignCampaignRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyAction = requestParameters.shopifyAction;
        if (shopifyAction === null || shopifyAction === undefined) {
            throw new Error('Required parameter shopifyAction was null or undefined when calling shopifyControllerHandleShopifyActionAssignCampaign.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/integrations/shopify/actions/assign-campaign`,
            shopifyAction,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerHandleShopifyActionGetDownloadLink(requestParameters: ShopifyControllerHandleShopifyActionGetDownloadLinkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ShopifyActionResult>;
    public shopifyControllerHandleShopifyActionGetDownloadLink(requestParameters: ShopifyControllerHandleShopifyActionGetDownloadLinkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ShopifyActionResult>>;
    public shopifyControllerHandleShopifyActionGetDownloadLink(requestParameters: ShopifyControllerHandleShopifyActionGetDownloadLinkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ShopifyActionResult>>;
    public shopifyControllerHandleShopifyActionGetDownloadLink(requestParameters: ShopifyControllerHandleShopifyActionGetDownloadLinkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const shopifyAction = requestParameters.shopifyAction;
        if (shopifyAction === null || shopifyAction === undefined) {
            throw new Error('Required parameter shopifyAction was null or undefined when calling shopifyControllerHandleShopifyActionGetDownloadLink.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ShopifyActionResult>(`${this.configuration.basePath}/v1/programs/integrations/shopify/actions/get-download-link`,
            shopifyAction,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerHandleShopifyActionRemoveBalance(requestParameters: ShopifyControllerHandleShopifyActionRemoveBalanceRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerHandleShopifyActionRemoveBalance(requestParameters: ShopifyControllerHandleShopifyActionRemoveBalanceRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerHandleShopifyActionRemoveBalance(requestParameters: ShopifyControllerHandleShopifyActionRemoveBalanceRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerHandleShopifyActionRemoveBalance(requestParameters: ShopifyControllerHandleShopifyActionRemoveBalanceRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyAction = requestParameters.shopifyAction;
        if (shopifyAction === null || shopifyAction === undefined) {
            throw new Error('Required parameter shopifyAction was null or undefined when calling shopifyControllerHandleShopifyActionRemoveBalance.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/integrations/shopify/actions/remove-balance`,
            shopifyAction,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerHandleShopifyActionSendPush(requestParameters: ShopifyControllerHandleShopifyActionSendPushRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerHandleShopifyActionSendPush(requestParameters: ShopifyControllerHandleShopifyActionSendPushRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerHandleShopifyActionSendPush(requestParameters: ShopifyControllerHandleShopifyActionSendPushRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerHandleShopifyActionSendPush(requestParameters: ShopifyControllerHandleShopifyActionSendPushRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyAction = requestParameters.shopifyAction;
        if (shopifyAction === null || shopifyAction === undefined) {
            throw new Error('Required parameter shopifyAction was null or undefined when calling shopifyControllerHandleShopifyActionSendPush.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/integrations/shopify/actions/send-push`,
            shopifyAction,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listen to shopify \&#39;customers/redact\&#39; topic
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerRequestToDeleteCustomerData(requestParameters: ShopifyControllerRequestToDeleteCustomerDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerRequestToDeleteCustomerData(requestParameters: ShopifyControllerRequestToDeleteCustomerDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerRequestToDeleteCustomerData(requestParameters: ShopifyControllerRequestToDeleteCustomerDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerRequestToDeleteCustomerData(requestParameters: ShopifyControllerRequestToDeleteCustomerDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyCustomerRedact = requestParameters.shopifyCustomerRedact;
        if (shopifyCustomerRedact === null || shopifyCustomerRedact === undefined) {
            throw new Error('Required parameter shopifyCustomerRedact was null or undefined when calling shopifyControllerRequestToDeleteCustomerData.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/integrations/shopify/customer-deletion`,
            shopifyCustomerRedact,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listen to shopify \&#39;shop/redact\&#39; topic
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerRequestToDeleteStoreData(requestParameters: ShopifyControllerRequestToDeleteStoreDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerRequestToDeleteStoreData(requestParameters: ShopifyControllerRequestToDeleteStoreDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerRequestToDeleteStoreData(requestParameters: ShopifyControllerRequestToDeleteStoreDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerRequestToDeleteStoreData(requestParameters: ShopifyControllerRequestToDeleteStoreDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyStoreRedact = requestParameters.shopifyStoreRedact;
        if (shopifyStoreRedact === null || shopifyStoreRedact === undefined) {
            throw new Error('Required parameter shopifyStoreRedact was null or undefined when calling shopifyControllerRequestToDeleteStoreData.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/integrations/shopify/store-deletion`,
            shopifyStoreRedact,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listen to shopify \&#39;customers/data_request\&#39; topic
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerRequestViewCustomerStoredData(requestParameters: ShopifyControllerRequestViewCustomerStoredDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerRequestViewCustomerStoredData(requestParameters: ShopifyControllerRequestViewCustomerStoredDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerRequestViewCustomerStoredData(requestParameters: ShopifyControllerRequestViewCustomerStoredDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerRequestViewCustomerStoredData(requestParameters: ShopifyControllerRequestViewCustomerStoredDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const shopifyCustomerRequest = requestParameters.shopifyCustomerRequest;
        if (shopifyCustomerRequest === null || shopifyCustomerRequest === undefined) {
            throw new Error('Required parameter shopifyCustomerRequest was null or undefined when calling shopifyControllerRequestViewCustomerStoredData.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/integrations/shopify/customer-request`,
            shopifyCustomerRequest,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listen to shopify \&#39;app_subscriptions/update\&#39; topic
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerSubscriptionUpdate(requestParameters: ShopifyControllerSubscriptionUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerSubscriptionUpdate(requestParameters: ShopifyControllerSubscriptionUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerSubscriptionUpdate(requestParameters: ShopifyControllerSubscriptionUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerSubscriptionUpdate(requestParameters: ShopifyControllerSubscriptionUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling shopifyControllerSubscriptionUpdate.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/shopify/subscriptions`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shopifyControllerTokenExchange(requestParameters: ShopifyControllerTokenExchangeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public shopifyControllerTokenExchange(requestParameters: ShopifyControllerTokenExchangeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public shopifyControllerTokenExchange(requestParameters: ShopifyControllerTokenExchangeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public shopifyControllerTokenExchange(requestParameters: ShopifyControllerTokenExchangeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const tokenExchange = requestParameters.tokenExchange;
        if (tokenExchange === null || tokenExchange === undefined) {
            throw new Error('Required parameter tokenExchange was null or undefined when calling shopifyControllerTokenExchange.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling shopifyControllerTokenExchange.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/shopify/token-exchange`,
            tokenExchange,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
