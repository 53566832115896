import { ActionReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { RootState } from "../..";
import { PROGRAM_FEATURE_KEY } from "../../program/state";
import { environment } from "../../../../environments/environment";
import { APP_FEATURE_KEY, persistedKeys as appPersistedKeys } from "./../state";
import { persistedKeys as programPersistedKeys } from "../../program/state";
import { SHOPIFY_HOST } from "../../../constants";
import { WalletParams } from "../../../interfaces";

export const localStorageReduxSyncMetaReducer = (reducer: ActionReducer<RootState>): ActionReducer<void> => {
  let programSlug = "";

  // Convercus
  try {
    const { programId }: WalletParams = JSON.parse(window.name);
    if (programId) programSlug = programId;
  } catch (error) {}

  // Shopify
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const shop = urlParams.get("shop");

    if (shop) programSlug = shop?.replace(SHOPIFY_HOST, "");
  } catch (error) {}

  return localStorageSync({
    keys: [{ [APP_FEATURE_KEY]: appPersistedKeys }, { [PROGRAM_FEATURE_KEY]: programPersistedKeys }],
    rehydrate: true,
    storageKeySerializer: key => `${programSlug}_${environment.name}_${key}`,
  })(reducer);
};
