export enum AppRoutes {
  root = "",
  design = "design",
  campaigns = "campaigns",
  engagement = "engagement",
  analytics = "analytics",
  customers = "customers",
  wallets = "wallets",
  email = "emails",
  webapp = "webapp",
  createProgram = "create-program",
  notFound = "not-found",
  login = "login",
  triggeredBased = "triggered-based",
  locationBased = "location-based",
  scheduled = "scheduled",
  links = "links",
  locations = "locations",
  add = "add",
  settings = "settings",
  customerFlows = "customer-flows",
  integrations = "integrations",
  technical = "technical",
  oauth = "oauth",
  callback = "callback",
  onboarding = "onboarding",
  subscription = "subscription",
}

export enum AppRouteParams {
  programSlug = "programSlug",
  locationId = "locationId",
  campaignId = "campaignId",
  linkId = "linkId",
  customerId = "customerId",
}

export enum AppQueryStrings {
  chargeId = "charge_id",
}

export enum Languages {
  de = "de",
  es = "es",
  en = "en",
}

export enum Platforms {
  all = "*",
  ios = "ios",
  android = "android",
}

export enum WalletSideVisible {
  Front = "front",
  Back = "back",
}

export enum WalletPlatform {
  Apple = "ios",
  Google = "android",
}

export enum ProgramAnalyticsAggregateEnum {
  PASSES_INSTALLED = "PASSES_INSTALLED",
  CLICKS = "CLICKS",
  PUSHES_SENT = "PUSHES_SENT",
  WEBAPP_VIEWS = "WEBAPP_VIEWS",
}

export enum RegisterFieldValues {
  disabled = "disabled",
  optional = "optional",
  mandatory = "mandatory",
}

export enum UpdateCustomerBalance {
  add = "add",
  remove = "remove",
}

export enum CardType {
  notification = "notification",
  wallet = "wallet",
}
