import { createReducer, on } from "@ngrx/store";
import {
  ProgramCampaigns,
  CampaignLinks,
  ProgramLocations,
  ProgramState,
  initialState,
  ApiKeys,
  Customers,
  ProgramFeatures,
  ProgramIntegrations,
} from "./state";
import * as programActions from "./actions";
import { Link, ProgramIntegration } from "../../services/api";
import { PAGINATION_SIZE } from "../../constants";
import { HttpStatusCode } from "@angular/common/http";

export const programReducer = createReducer(
  initialState,

  on(programActions.getProgram, (state, { loadingFullScreen }): ProgramState => {
    if (loadingFullScreen) {
      return { ...state, loadingFullScreen: true };
    } else {
      return { ...state, loading: true };
    }
  }),

  on(programActions.getProgramSuccess, (state, { program, loadingFullScreen }): ProgramState => {
    if (loadingFullScreen) {
      return { ...state, loadingFullScreen: false, program };
    } else {
      return { ...state, loading: false, program };
    }
  }),

  on(programActions.getProgramFailure, (state, { error, loadingFullScreen }): ProgramState => {
    let program = state.program;

    if (error["statusCode"] === HttpStatusCode.NotFound) program = null;

    if (loadingFullScreen) return { ...state, program, loadingFullScreen: false };
    else return { ...state, program, loading: false };
  }),

  on(
    programActions.getAnalytics,
    programActions.getAnalyticsAggregate,
    (state, { loading }): ProgramState => ({ ...state, loading: true, loadingAnalytics: loading }),
  ),

  on(
    programActions.persistProgram,
    programActions.getIntegrations,
    programActions.createProgram,
    programActions.getProgramCustomers,
    programActions.getCustomerById,
    programActions.getProgramCampaigns,
    programActions.getProgramLocations,
    programActions.addLocation,
    programActions.updateLocation,
    programActions.deleteLocation,
    programActions.createCampaign,
    programActions.updateCampaign,
    programActions.deleteCampaign,
    programActions.getProgramLinks,
    programActions.getCampaignLinks,
    programActions.getProgramApiKeys,
    programActions.generateApiKey,
    programActions.deleteApiKey,
    programActions.getProgramIntegrations,
    programActions.sendPush,
    programActions.addIntegration,
    programActions.addProgramIntegration,
    programActions.updateProgramIntegration,
    programActions.updateProgram,
    programActions.klaviyoCallback,
    programActions.getFeatures,
    programActions.getFeaturesFailure,
    programActions.getSegments,
    programActions.getSegmentsFailure,
    programActions.addPointsToCustomer,
    programActions.removePointsToCustomer,
    programActions.getCustomerEvents,
    programActions.getProgramSubscriptionInfo,
    programActions.getCampaignById,
    programActions.getCampaignAnalytics,
    programActions.checkCampaignSlug,
    (state): ProgramState => ({ ...state, loading: true }),
  ),

  on(programActions.klaviyoAuthorize, (state): ProgramState => ({ ...state, loading: true, loadingKlaviyoAuth: true })),

  on(programActions.createProgramSubscription, (state): ProgramState => ({ ...state, loading: true, loadingCreateSubscription: true })),

  on(
    programActions.setLoadingCreateProgramSubscription,
    (state, { loadingCreateSubscription }): ProgramState => ({
      ...state,
      loadingCreateSubscription,
    }),
  ),

  on(
    programActions.setLoadingKlaviyoAuth,
    (state, { loadingKlaviyoAuth }): ProgramState => ({
      ...state,
      loadingKlaviyoAuth,
    }),
  ),

  on(
    programActions.getFeaturesSuccess,
    (state, { features }): ProgramState => ({
      ...state,
      features: features.reduce((features, feature) => {
        features[feature.id] = feature;
        return features;
      }, {} as ProgramFeatures),
    }),
  ),

  on(programActions.getSegmentsSuccess, (state, { segments }): ProgramState => ({ ...state, segments })),

  on(programActions.setCustomersSearchTerm, (state, { customersSearchTerm }): ProgramState => ({ ...state, customersSearchTerm })),

  on(
    programActions.setIntegrationsBySearchTerm,
    (state, { integrationsSearchTerm }): ProgramState => ({ ...state, integrationsSearchTerm }),
  ),

  on(
    programActions.resetCustomersPagination,
    (state): ProgramState => ({
      ...state,
      customersSearchTerm: "",
      customersPaginationData: {
        limit: PAGINATION_SIZE,
        page: 1,
        total: 0,
      },
    }),
  ),

  on(programActions.updateProgramSuccess, (state, { program }): ProgramState => ({ ...state, loading: false, program })),

  on(programActions.getProgramLinksSuccess, (state, { programLinks }): ProgramState => ({ ...state, programLinks, loading: false })),

  on(programActions.persistProgramSuccess, (state, { program }): ProgramState => ({ ...state, program, loading: false })),

  on(programActions.persistProgramLinksSuccess, (state, { programLinks }): ProgramState => ({ ...state, programLinks, loading: false })),

  on(programActions.getIntegrationsSuccess, (state, { integrations }): ProgramState => ({ ...state, integrations, loading: false })),

  on(
    programActions.getProgramIntegrationsSuccess,
    (state, { programIntegrations }): ProgramState => ({
      ...state,
      programIntegrations: programIntegrations.reduce((programIntegrations, programIntegration) => {
        programIntegrations[programIntegration.id] = programIntegration;
        return programIntegrations;
      }, {} as ProgramIntegrations),
      loading: false,
    }),
  ),

  on(
    programActions.getAnalyticsSuccess,
    (state, { analytics }): ProgramState => ({ ...state, analytics, loading: false, loadingAnalytics: false }),
  ),

  on(
    programActions.getAnalyticsAggregateSuccess,
    (state, { analyticsAggregate, analyticsAggregateType }): ProgramState => ({
      ...state,
      analyticsAggregate: {
        ...state.analyticsAggregate,
        [analyticsAggregateType]: analyticsAggregate,
      },
      loading: false,
      loadingAnalytics: false,
    }),
  ),

  on(programActions.createProgramSuccess, (state, { program }): ProgramState => ({ ...state, program, loading: false })),

  on(
    programActions.updateWallet,
    (state, { wallet }): ProgramState => ({
      ...state,
      program: {
        ...state.program,
        configurations: {
          ...state.program.configurations,
          wallet,
        },
      },
    }),
  ),

  on(
    programActions.updateEmail,
    (state, { email }): ProgramState => ({
      ...state,
      program: {
        ...state.program,
        configurations: {
          ...state.program.configurations,
          email,
        },
      },
    }),
  ),

  on(
    programActions.updateWebapp,
    (state, { web }): ProgramState => ({
      ...state,
      program: {
        ...state.program,
        configurations: {
          ...state.program.configurations,
          web,
        },
      },
    }),
  ),

  on(
    programActions.addTemporalProgramLink,
    (state): ProgramState => ({ ...state, programLinks: [...state.programLinks, { url: "", label: "" } as Link] }),
  ),

  on(programActions.updateTemporalProgramLink, (state, { index, property, value }): ProgramState => {
    const programLinks = [...state.programLinks];
    const programLink = { ...programLinks[index], [property]: value };
    programLinks.splice(index, 1, programLink);

    return { ...state, programLinks };
  }),

  on(programActions.deleteTemporalProgramLink, (state, { index }): ProgramState => {
    const programLinks = [...state.programLinks];
    programLinks.splice(index, 1);

    return { ...state, programLinks };
  }),

  on(
    programActions.getProgramCustomersSuccess,
    (state, { limit, customers }): ProgramState => ({
      ...state,
      customers: customers.data.reduce((customers, customer) => {
        customers[customer.email] = customer;
        return customers;
      }, {} as Customers),
      customersPaginationData: {
        limit,
        page: customers.page,
        total: customers.total,
      },
      loading: false,
    }),
  ),

  on(
    programActions.getCustomerEventsSuccess,
    (state, { customerId, paginatedCustomerEvents }): ProgramState => ({
      ...state,
      customersEvents: {
        ...state.customersEvents,
        [customerId]:
          paginatedCustomerEvents.page === 1
            ? [...paginatedCustomerEvents.data]
            : [...(state.customersEvents[customerId] || []), ...paginatedCustomerEvents.data],
      },
      customersEventsPaginationData: {
        ...state.customersEventsPaginationData,
        page: paginatedCustomerEvents.page,
        total: paginatedCustomerEvents.total,
      },
      loading: false,
    }),
  ),

  on(
    programActions.getProgramCampaignsSuccess,
    (state, { campaigns }): ProgramState => ({
      ...state,
      campaigns: campaigns.reduce((campaigns, campaign) => {
        campaigns[campaign.id] = campaign;
        return campaigns;
      }, {} as ProgramCampaigns),
      loading: false,
    }),
  ),

  on(
    programActions.getProgramLocationsSuccess,
    (state, { locations }): ProgramState => ({
      ...state,
      locations: locations.reduce((locations, location) => {
        locations[location.id] = location;
        return locations;
      }, {} as ProgramLocations),
      loading: false,
    }),
  ),

  on(
    programActions.addLocationSuccess,
    programActions.updateLocationSuccess,
    (state, { location }): ProgramState => ({
      ...state,
      locations: {
        ...state.locations,
        [location.id]: location,
      },
      formError: "",
      loading: false,
    }),
  ),

  on(
    programActions.deleteLocationSuccess,
    (state, { locationId }): ProgramState => ({
      ...state,
      locations: Object.values(state.locations).reduce((locations, location) => {
        if (location.id !== locationId) locations[location.id] = location;
        return locations;
      }, {} as ProgramLocations),
      formError: "",
      loading: false,
    }),
  ),

  on(
    programActions.createCampaignSuccess,
    programActions.updateCampaignSuccess,
    (state, { campaign }): ProgramState => ({
      ...state,
      campaigns: {
        ...state.campaigns,
        [campaign.id]: campaign,
      },
      formError: "",
      loading: false,
    }),
  ),

  on(
    programActions.deleteCampaignSuccess,
    (state, { campaignId }): ProgramState => ({
      ...state,
      campaigns: Object.values(state.campaigns).reduce((campaigns, campaign) => {
        if (campaign.id !== campaignId) campaigns[campaign.id] = campaign;
        return campaigns;
      }, {} as ProgramCampaigns),
      formError: "",
      loading: false,
    }),
  ),

  on(
    programActions.getCampaignLinksSuccess,
    (state, { campaignLinks }): ProgramState => ({
      ...state,
      campaignLinks: campaignLinks.reduce((campaignLinks, campaignLink) => {
        campaignLinks[campaignLink.id] = campaignLink;
        return campaignLinks;
      }, {} as CampaignLinks),
      loading: false,
    }),
  ),

  on(
    programActions.getCampaignByIdSuccess,
    (state, { campaign }): ProgramState => ({
      ...state,
      campaigns: {
        ...state.campaigns,
        [campaign.id]: campaign,
      },
      loading: false,
    }),
  ),

  on(
    programActions.getCampaignAnalyticsSuccess,
    (state, { campaignId, campaignAnalytic }): ProgramState => ({
      ...state,
      campaignAnalytics: {
        ...state.campaignAnalytics,
        [campaignId]: campaignAnalytic,
      },
      loading: false,
    }),
  ),

  on(
    programActions.updateCampaignLinkSuccess,
    (state, { campaignLink }): ProgramState => ({
      ...state,
      campaignLinks: {
        ...state.campaignLinks,
        [campaignLink.id]: campaignLink,
      },
      formError: "",
      loading: false,
    }),
  ),

  on(
    programActions.getProgramApiKeysSuccess,
    (state, { apiKeys }): ProgramState => ({
      ...state,
      apiKeys: apiKeys.reduce((apiKeys, apiKey) => {
        apiKeys[apiKey.id] = apiKey;
        return apiKeys;
      }, {} as ApiKeys),
      loading: false,
    }),
  ),

  on(
    programActions.generateApiKeySuccess,
    (state, { apiKey }): ProgramState => ({
      ...state,
      apiKeys: {
        ...state.apiKeys,
        [apiKey.id]: apiKey,
      },
      loading: false,
    }),
  ),

  on(
    programActions.deleteApiKeySuccess,
    (state, { apiKeyId }): ProgramState => ({
      ...state,
      apiKeys: Object.values(state.apiKeys).reduce((apiKeys, apiKey) => {
        if (apiKey.id !== apiKeyId) apiKeys[apiKey.id] = apiKey;
        return apiKeys;
      }, {} as ApiKeys),
      loading: false,
    }),
  ),

  on(
    programActions.getCustomerByIdSuccess,
    (state, { customer }): ProgramState => ({
      ...state,
      customersData: {
        ...state.customersData,
        [customer.customer.id]: customer,
      },
      loading: false,
    }),
  ),

  on(programActions.resetFormError, (state): ProgramState => ({ ...state, formError: "" })),

  on(programActions.resetCampaignSlug, (state): ProgramState => ({ ...state, campaignSlug: "" })),

  on(
    programActions.sendPushSuccess,
    (state): ProgramState => ({
      ...state,
      formError: "",
      loading: false,
    }),
  ),

  on(programActions.toggleIntegrationFilter, (state, { integrationFilter }): ProgramState => {
    const newArray = [...state.integrationsFilters];

    const index = newArray.indexOf(integrationFilter);

    if (index === -1) newArray.push(integrationFilter);
    else newArray.splice(index, 1);

    return {
      ...state,
      integrationsFilters: newArray,
    };
  }),

  on(
    programActions.addProgramIntegrationSuccess,
    programActions.updateProgramIntegrationSuccess,
    (state, { programIntegration, integrationName }): ProgramState => ({
      ...state,
      programIntegrations: {
        ...state.programIntegrations,
        [programIntegration.id]: { ...programIntegration, integration: { name: integrationName } } as ProgramIntegration,
      },
      loading: false,
    }),
  ),

  on(
    programActions.addPointsToCustomerSuccess,
    programActions.removePointsToCustomerSuccess,
    (state, { customer }): ProgramState => ({
      ...state,
      formError: "",
      customersData: {
        ...state.customersData,
        [customer.customer.id]: customer,
      },
      loading: false,
    }),
  ),

  on(
    programActions.getProgramSubscriptionInfoSuccess,
    (state, { subscriptionInfo }): ProgramState => ({
      ...state,
      subscriptionInfo,
      loading: false,
    }),
  ),

  on(
    programActions.checkCampaignSlugSuccess,
    (state, { response }): ProgramState => ({
      ...state,
      campaignSlug: response.slug,
      loading: false,
    }),
  ),

  on(
    programActions.addLocationFailure,
    programActions.updateLocationFailure,
    programActions.deleteLocationFailure,
    programActions.createCampaignFailure,
    programActions.updateCampaignFailure,
    programActions.deleteCampaignFailure,
    programActions.sendPushFailure,
    programActions.addProgramIntegrationFailure,
    programActions.updateProgramIntegrationFailure,
    programActions.addPointsToCustomerFailure,
    programActions.removePointsToCustomerFailure,
    (state, { formError }): ProgramState => ({ ...state, formError, loading: false }),
  ),

  on(
    programActions.getAnalyticsFailure,
    programActions.getAnalyticsAggregateFailure,
    (state): ProgramState => ({ ...state, loading: false, loadingAnalytics: false }),
  ),

  on(
    programActions.persistProgramFailure,
    programActions.getIntegrationsFailure,
    programActions.createProgramFailure,
    programActions.getProgramCustomersFailure,
    programActions.getCustomerByIdFailure,
    programActions.getProgramCampaignsFailure,
    programActions.getProgramLocationsFailure,
    programActions.getProgramLinksFailure,
    programActions.getCampaignLinksFailure,
    programActions.getProgramApiKeysFailure,
    programActions.deleteApiKeyFailure,
    programActions.getProgramIntegrationsFailure,
    programActions.addIntegrationFailure,
    programActions.addIntegrationSuccess,
    programActions.updateProgramFailure,
    programActions.klaviyoAuthorizeSuccess,
    programActions.klaviyoAuthorizeFailure,
    programActions.klaviyoCallbackSuccess,
    programActions.klaviyoCallbackFailure,
    programActions.getCustomerEventsFailure,
    programActions.getProgramSubscriptionInfoFailure,
    programActions.createProgramSubscriptionFailure,
    programActions.createProgramSubscriptionSuccess,
    programActions.getCampaignByIdFailure,
    programActions.getCampaignAnalyticsFailure,
    programActions.checkCampaignSlugFailure,
    (state): ProgramState => ({ ...state, loading: false }),
  ),
);
