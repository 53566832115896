import { AppRoutes, CardType, Languages, ProgramAnalyticsAggregateEnum, WalletPlatform, WalletSideVisible } from "../../enums";
import { Program } from "../../services/api";

export const APP_FEATURE_KEY = "app";

export interface AnalyticsFilters {
  aggregateType?: ProgramAnalyticsAggregateEnum;
  campaignId?: string;
  from?: string;
  to?: string;
}

export interface AppState {
  language: Languages;
  uploadImageLoading: boolean;
  dirtyForm: boolean;
  accessToken: string;
  userId: string;
  iframeHost: string;
  iframeShop: string;
  iframeIdToken: string;
  iframeProgramId: string;
  iframeProgramName: string;
  iframeIntegrationName: Program.IntegrationEnum;
  analyticsFilters: AnalyticsFilters;
  sideNav: {
    open: boolean;
    active: boolean;
    mobile: boolean;
    hasLogOut: boolean;
  };
  pages: {
    [AppRoutes.design]: {
      walletPlatform: WalletPlatform;
      walletSideVisible: WalletSideVisible;
    };
    [AppRoutes.locationBased]: {
      walletPlatform: WalletPlatform;
    };
    [AppRoutes.scheduled]: {
      walletPlatform: WalletPlatform;
    };
    [AppRoutes.triggeredBased]: {
      type: CardType;
      walletPlatform: WalletPlatform;
    };
  };
}

export const initialState: AppState = {
  language: Languages.en,
  uploadImageLoading: false,
  dirtyForm: false,
  accessToken: null,
  userId: null,
  iframeHost: null,
  iframeShop: null,
  iframeIdToken: null,
  iframeProgramId: null,
  iframeProgramName: null,
  iframeIntegrationName: null,
  analyticsFilters: {
    aggregateType: ProgramAnalyticsAggregateEnum.PASSES_INSTALLED,
    campaignId: null,
    from: null,
    to: null,
  },
  sideNav: {
    open: false,
    active: true,
    mobile: false,
    hasLogOut: true,
  },
  pages: {
    [AppRoutes.design]: {
      walletPlatform: WalletPlatform.Apple,
      walletSideVisible: WalletSideVisible.Front,
    },
    [AppRoutes.locationBased]: {
      walletPlatform: WalletPlatform.Apple,
    },
    [AppRoutes.scheduled]: {
      walletPlatform: WalletPlatform.Apple,
    },
    [AppRoutes.triggeredBased]: {
      type: CardType.notification,
      walletPlatform: WalletPlatform.Apple,
    },
  },
};

export const persistedKeys: (keyof AppState)[] = [
  "language",
  "accessToken",
  "userId",
  "analyticsFilters",
  "iframeHost",
  "iframeShop",
  "iframeProgramId",
  "iframeIntegrationName",
  "sideNav",
];
