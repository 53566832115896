/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1121
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Link { 
    id: string;
    programId: string;
    label: string;
    alias: string;
    url: string;
    visibility: Link.VisibilityEnum;
    createdAt: string;
    updatedAt: string;
}
export namespace Link {
    export type VisibilityEnum = '0' | '1';
    export const VisibilityEnum = {
        _0: '0' as VisibilityEnum,
        _1: '1' as VisibilityEnum
    };
}


