import { AppRoutes } from "../../enums";
import { APP_FEATURE_KEY, AppState } from "./state";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const getState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

export const selectUploadImageLoading = createSelector(getState, state => state.uploadImageLoading);

export const selectLanguage = createSelector(getState, state => state.language);

export const selectAccessToken = createSelector(getState, state => state.accessToken);

export const selectDirtyForm = createSelector(getState, state => state.dirtyForm);

export const selectDesignWalletPlatform = createSelector(getState, state => state.pages.design.walletPlatform);

export const selectScheduledWalletPlatform = createSelector(getState, state => state.pages[AppRoutes.scheduled].walletPlatform);

export const selectLocationBasedWalletPlatform = createSelector(getState, state => state.pages[AppRoutes.locationBased].walletPlatform);

export const selectTriggeredBasedType = createSelector(getState, state => state.pages[AppRoutes.triggeredBased].type);

export const selectTriggeredBasedWalletPlatform = createSelector(getState, state => state.pages[AppRoutes.triggeredBased].walletPlatform);

export const selectDesignWalletSideVisible = createSelector(getState, state => state.pages.design.walletSideVisible);

export const selectSideNavStats = createSelector(getState, state => state.sideNav);

export const selectIframeHost = createSelector(getState, state => state.iframeHost);

export const selectIframeShop = createSelector(getState, state => state.iframeShop);

export const selectIframeProgramId = createSelector(getState, state => state.iframeProgramId);

export const selectIframeProgramName = createSelector(getState, state => state.iframeProgramName);

export const selectIframeIntegrationName = createSelector(getState, state => state.iframeIntegrationName);

export const selectAnalyticsFilters = createSelector(getState, state => state.analyticsFilters);

export const selectUserId = createSelector(getState, state => state.userId);
