import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { Store, StoreModule } from "@ngrx/store";
import { effects, reducers } from "./store";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { ApiModule, Configuration } from "./services/api";
import { initializeApp as appInitializer } from "./app.initializer.factory";
import { AppInterceptor } from "./interceptors/app/app.interceptor";
import { LanguageInterceptor } from "./interceptors/language/language.interceptor";
import { VersionInterceptor } from "./interceptors/version/version.interceptor";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DEFAULT_LANGUAGE } from "./constants";
import * as Sentry from "@sentry/angular-ivy";
import { metaReducers } from "./store";
import { ShopifyInterceptor } from "./interceptors/auth/shopify.interceptor";
import { registerPlugin } from "ngx-filepond";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { ConfirmComponentModule } from "./modals/confirm/confirm.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { TimezoneInterceptor } from "./interceptors/timezone/timezone.interceptor";
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import * as FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import * as FilePondPluginImagePreview from "filepond-plugin-image-preview";
import * as FilePondPluginImageCrop from "filepond-plugin-image-crop";
import * as FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { ShopifyAppBridgeService } from "./services/shopify-app-bridge/shopify-app-bridge.service";
import { Router } from "@angular/router";
import { GenerateApiKeyComponentModule } from "./modals/generate-api-key/generate-api-key.module";
import { UpdateBalanceComponentModule } from "./modals/update-balance/update-balance.module";
import { SendPushComponentModule } from "./modals/send-push/send-push.module";
import { ConvercusInterceptor } from "./interceptors/auth/convercus.interceptor";
import { PaymentRequiredInterceptor } from "./interceptors/payment-required/payment-required.interceptor";
import { ShopifyMultiPassComponentModule } from "./modals/shopify-multi-pass/shopify-multi-pass.module";
import { UnderstoodComponentModule } from "./modals/understood/understood.module";
import { UpdateIntegrationConfirmComponentModule } from "./modals/update-integration-confirm/update-integration-confirm.module";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageCrop);
registerPlugin(FilePondPluginImageTransform);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  const bustingParam = `?v=${new Date().getTime()}`;
  return new TranslateHttpLoader(http, "./assets/i18n/", `.json${bustingParam}`);
}

export const setApiConfig = (): Configuration => new Configuration({ basePath: environment.apiUrl });

Sentry.init({
  release: `jeripass-admin-app@${environment.release}`,
  dsn: "https://4cdac8c6cdd76242d361d709f7ea632f@o146990.ingest.us.sentry.io/4506943053365248",
  environment: environment.name,
  dist: environment.dist,
  attachStacktrace: true,
  autoSessionTracking: true,
  enabled: environment.name === "staging" || environment.name === "production",
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ApiModule.forRoot(setApiConfig),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideStorage(() => getStorage()),
    ConfirmComponentModule,
    GenerateApiKeyComponentModule,
    UpdateBalanceComponentModule,
    ShopifyMultiPassComponentModule,
    SendPushComponentModule,
    UnderstoodComponentModule,
    UpdateIntegrationConfirmComponentModule,
    MatProgressSpinnerModule,
    MatGoogleMapsAutocompleteModule.forRoot("AIzaSyDXWYVpAOCjvClVlUygVjnUanGeooDXNzs"),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ShopifyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ConvercusInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PaymentRequiredInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [Store, ShopifyAppBridgeService, Router],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
